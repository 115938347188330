import eWayBillEntryForm from './eWayBillEntryForm.js';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'eWayBill',
  watch: {
    currentPage: function() {
      this.getEwayBillGenerateData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getEwayBillGenerateData();
    }
  },
  components: { eWayBillEntryForm, DatePicker },
  data() {
    return {
      loader: false,
      showEwayBillDataEntryModal: false,
      unsubscribe: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      docType: {
        value: null,
        text: null
      },
      docNum: null,
      shipToGstin: null,
      billToGstin: null,
      eWayBillNum: null,
      docDateFrom: null,
      docDateTo: null,
      showValueSetModal: false,
      eWayBillData: [],
      eWayBillFields: [
        {
          key: 'documentType',
          label: 'Document Type',
          class: 'text-center'
        },
        {
          key: 'docNo',
          label: 'Document Number',
          class: 'text-center'
        },
        {
          key: 'documentDate',
          label: 'Document Date',
          class: 'text-center'
        },
        {
          key: 'bill_from_state',
          label: 'Bill From State',
          class: 'text-center'
        },
        {
          key: 'bill_to_state',
          label: 'Bill To State',
          class: 'text-center'
        },
        {
          key: 'ewbNo',
          label: 'E-Way Bill Number',
          class: 'text-center'
        }
      ],
      accessButtons: {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: true,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      },
      rowData: null
    };
  },
  validations: {
    docType: {
      text: { required }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.rowData = null;
          this.showEwayBillDataEntryModal = true;
        }
      }
    });
  },
  methods: {
    getEwayBillGenerateData() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          document_num: this.docNum,
          document_type: this.docType.value,
          bill_to_gstin: this.billToGstin,
          ship_to_gstin: this.shipToGstin,
          eway_bill_no: this.eWayBillNum
        };
        this.loader = true;
        this.$store
          .dispatch('eWayBill/getEwayBillGenerateData', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              const result = resp.data.data.page;
              this.eWayBillData = result;
              this.totalRows = resp.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    rowSelected(item) {
      this.rowData = item;
      this.showHideEwayBillDataEntryModal(true);
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.docDateFrom);
    },
    showHideEwayBillDataEntryModal(flag) {
      this.showEwayBillDataEntryModal = flag;
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.EWAY_BILL_DOC_TYPE) {
        this.docType = {
          text: item.value_meaning,
          value: item.value_code
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.docType.value) {
        this.docType = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.docNum) {
        this.docNum = null;
      } else if (vsetCode === this.billToGstin) {
        this.billToGstin = null;
      } else if (vsetCode === this.shipToGstin) {
        this.shipToGstin = null;
      } else if (vsetCode === this.eWayBillNum) {
        this.eWayBillNum = null;
      }
    },
    clear() {
      this.docType = {
        text: null,
        value: null
      };
      this.docNum = null;
      this.billToGstin = null;
      this.shipToGstin = null;
      this.eWayBillNum = null;
      this.docDateFrom = null;
      this.docDateTo = null;
      this.totalRows = null;
      this.eWayBillData = [];
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
