import DatePicker from 'vue2-datepicker';
import { ModelSelect, BasicSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
import { required } from 'vuelidate/lib/validators';
import updateEwayBill from './updateEwayBill';

export default {
  name: 'eWayBillEntryForm',
  props: ['rowData'],
  watch: {},
  components: { DatePicker, ModelSelect, BasicSelect, updateEwayBill },
  data() {
    return {
      loader: false,
      selectedTrxType: {
        value: null,
        text: null
      },
      trxTypeOptions: [],
      selectedSupplyType: {
        value: null,
        text: null
      },
      supplyTypeOptions: [],
      selectedSubType: {
        value: null,
        text: null
      },
      subTypeOptions: [],
      docTypeList: [],
      docType: {
        value: null,
        text: null
      },
      docNum: null,
      nameBillFrom: null,
      docDate: null,
      gstinBillFrom: null,
      stateListBillFrom: [],
      selectedStateBillFrom: {
        value: null,
        text: null
      },
      pinDesFrom: null,
      address1DesFrom: null,
      address2DesFrom: null,
      placeDesFrom: null,
      nameBillTo: null,
      gstinBillTo: null,
      stateListBillTo: [],
      selectedStateBillTo: {
        value: null,
        text: null
      },
      address1ShipTo: null,
      address2ShipTo: null,
      placeShipTo: null,
      pinDesTo: null,
      eWayBillItemDtlsData: [
        {
          productName: null,
          productDesc: null,
          hsnCode: null,
          quantity: null,
          qtyUnit: null,
          // taxable_value: null,
          cgstRate: null,
          sgstRate: null,
          cessRate: null,
          igstRate: null,
          taxableAmount: null,
          cgst_amt: null,
          sgst_amt: null,
          igst_amt: null,
          cess_amt: null
          // total_inv_value: null
        }
      ],
      eWayBillItemDtlsFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'productName',
          label: 'Product Name',
          class: 'text-center'
        },
        {
          key: 'productDesc',
          label: 'Description',
          class: 'text-center'
        },
        {
          key: 'hsnCode',
          label: 'HSN',
          class: 'text-center'
        },
        {
          key: 'quantity',
          label: 'Quantity',
          class: 'text-center'
        },
        {
          key: 'qtyUnit',
          label: 'UQC',
          class: 'text-center'
        },
        {
          key: 'taxableAmount',
          label: 'Taxable Value(Rs.)',
          class: 'text-center'
        },
        {
          key: 'cgstRate',
          label: 'Tax Rate (CGST)',
          class: 'text-center'
        },
        {
          key: 'sgstRate',
          label: 'Tax Rate (SGST)',
          class: 'text-center'
        },
        {
          key: 'igstRate',
          label: 'Tax Rate (IGST)',
          class: 'text-center'
        },
        {
          key: 'cessRate',
          label: 'Tax Rate (CESS)',
          class: 'text-center'
        },
        // {
        //   key: 'taxableAmount',
        //   label: 'Total Amount',
        //   class: 'text-center'
        // },
        {
          key: 'cgst_amt',
          label: 'CGST Amount',
          class: 'text-center'
        },
        {
          key: 'sgst_amt',
          label: 'SGST Amount',
          class: 'text-center'
        },
        {
          key: 'igst_amt',
          label: 'IGST Amount',
          class: 'text-center'
        },
        {
          key: 'cess_amt',
          label: 'CESS Amount',
          class: 'text-center'
        }
        // {
        //   key: 'total_inv_value',
        //   label: 'Total Inv Value',
        //   class: 'text-center'
        // }
      ],
      transporterName: null,
      transporterId: null,
      transDistance: null,
      selectedMode: {
        value: null,
        text: null
      },
      modeOptions: [],
      selectedVehicleType: {
        value: null,
        text: null
      },
      vehicleTypeOptions: [],
      vehicleNum: null,
      trnsportDocNum: null,
      trnsportDocDate: null,
      totalAmount: 0,
      cgstAmount: 0,
      sgstAmount: 0,
      igstAmount: 0,
      cessAmount: 0,
      totalInvValue: 0,
      editMode: false,
      eWayBillNum: null,
      updateFormType: null,
      showUpdateEwayBillModal: false,
      accessButtons: {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: true,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      },
      updateProps: {},
      updateButtonsFlag: false,
      gebButtonFlag: false,
      extendEwayBillFlag: false,
      cancelEwayBillFlag: false,
      gebId: null
    };
  },
  validations: {
    selectedSupplyType: {
      text: { required }
    },
    selectedTrxType: {
      text: { required }
    },
    selectedSubType: {
      text: { required }
    },
    docType: {
      text: { required }
    },
    docNum: { required },
    docDate: { required },
    gstinBillFrom: { required },
    gstinBillTo: { required },
    pinDesFrom: { required },
    pinDesTo: { required },
    transDistance: { required }
  },
  mounted() {
    this.getStateDtls();
    this.getSupplyTypeDtls();
    this.getSubSupplyTypeDtls();
    this.getTrxTypeDtls();
    this.getDocTypeDtls();
    this.getModeDtls();
    this.getVehicleTypeDtls();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && !this.showUpdateEwayBillModal) {
          this.editMode = false;
          this.saveEwayBill();
        }
        if (actionName === 'update' && !this.showUpdateEwayBillModal) {
          this.editMode = true;
        }
      }
    });
    if (this.rowData) {
      if (this.rowData.gebId) {
        this.gebId = this.rowData.gebId;
        this.getEwayBillById();
      }
      this.updateButtonsFlag = this.rowData.ewbNo ? true : false;
      this.gebButtonFlag = this.rowData.gebId ? true : false;
      this.eWayBillNum = this.rowData.ewbNo;
    } else {
      this.editMode = true;
    }
  },
  methods: {
    getStateDtls() {
      const payload = {
        country_id: 100
      };
      this.loader = true;
      this.$store
        .dispatch('eWayBill/getValueSetStateData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            const itemDetails = result.map(ele => {
              return {
                value: ele.state_tax_code,
                text: ele.state
              };
            });
            this.stateListBillFrom = itemDetails;
            this.stateListBillTo = itemDetails;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    toUpperCaseGstin(type, item) {
      if (type === 'From') {
        this.gstinBillFrom = this.gstinBillFrom.toUpperCase();
      } else if (type === 'To') {
        this.gstinBillTo = this.gstinBillTo.toUpperCase();
      } else if (type === 'uqc') {
        item.qtyUnit = item.qtyUnit.toUpperCase();
      } else if (type === 'transPortId') {
        this.transporterId = this.transporterId.toUpperCase();
      } else if (type === 'vehicleNum') {
        this.vehicleNum = this.vehicleNum.toUpperCase();
      }
    },
    getSupplyTypeDtls() {
      this.loader = true;
      this.$store
        .dispatch('eWayBill/getValueSetSupplyData')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.page;
            const itemDetails = result.map(ele => {
              return {
                value: ele.value_code,
                text: ele.value_meaning
              };
            });
            this.supplyTypeOptions = itemDetails;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getSubSupplyTypeDtls() {
      this.loader = true;
      this.$store
        .dispatch('eWayBill/getValueSetSubSupplyData')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.page;
            const itemDetails = result.map(ele => {
              return {
                value: ele.value_code,
                text: ele.value_meaning
              };
            });
            this.subTypeOptions = itemDetails;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getTrxTypeDtls() {
      this.loader = true;
      this.$store
        .dispatch('eWayBill/getValueSetTrxTypeData')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.page;
            const itemDetails = result.map(ele => {
              return {
                value: ele.value_code,
                text: ele.value_meaning
              };
            });
            this.trxTypeOptions = itemDetails;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getDocTypeDtls() {
      this.loader = true;
      this.$store
        .dispatch('eWayBill/getValueSetDocTypeData')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.page;
            const itemDetails = result.map(ele => {
              return {
                value: ele.value_code,
                text: ele.value_meaning
              };
            });
            this.docTypeList = itemDetails;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getModeDtls() {
      this.loader = true;
      this.$store
        .dispatch('eWayBill/getValueSetModeData')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.page;
            const itemDetails = result.map(ele => {
              return {
                value: ele.value_code,
                text: ele.value_meaning
              };
            });
            this.modeOptions = itemDetails;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getVehicleTypeDtls() {
      this.$store
        .dispatch('eWayBill/getValueSetVehicleTypeData')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.page;
            const itemDetails = result.map(ele => {
              return {
                value: ele.value_code,
                text: ele.value_meaning
              };
            });
            this.vehicleTypeOptions = itemDetails;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    autoFilledData(item) {
      this.selectedSupplyType = {
        value: item.supplyType,
        text: item.supplyType
      };
      this.selectedTrxType = {
        value: item.transactionType,
        text: item.transactionType
      };
      this.selectedSubType = {
        value: item.subSupplyType,
        text: item.subSupplyType
      };
      this.docType = {
        value: item.docType,
        text: item.docType
      };
      this.docNum = item.docNo;
      this.docDate = item.docDate;
      this.nameBillFrom = item.fromTrdName;
      this.gstinBillFrom = item.fromGstin;
      this.selectedStateBillFrom = {
        value: item.fromStateCode,
        text: item.fromStateCode
      };
      this.address1DesFrom = item.fromAddr1;
      this.address2DesFrom = item.fromAddr2;
      this.placeDesFrom = item.fromPlace;
      this.pinDesFrom = item.fromPincode;
      this.nameBillTo = item.toTrdName;
      this.gstinBillTo = item.toGstin;
      this.selectedStateBillTo = {
        value: item.toStateCode,
        text: item.toStateCode
      };
      this.address1ShipTo = item.toAddr1;
      this.address2ShipTo = item.toAddr2;
      this.placeShipTo = item.toPlace;
      this.pinDesTo = item.toPincode;
      this.transporterName = item.transporterName;
      this.transDistance = item.transDistance;
      this.transporterId = item.transporterId;
      this.selectedMode = {
        value: item.transMode,
        text: item.transMode
      };
      this.selectedVehicleType = {
        value: item.vehicleType,
        text: item.vehicleType
      };
      this.vehicleNum = item.vehicleNo;
      this.trnsportDocNum = item.transDocNo;
      this.trnsportDocDate = item.transDocDate;
      // this.totalAmount = item.totValue;
      // this.cgstAmount = item.cgstValue;
      // this.sgstAmount = item.sgstValue;
      // this.igstAmount = item.igstValue;
      // this.cessAmount = item.cessValue;
      // this.totalInvValue = item.totInvValue;
      const data = item.itemList.map(detail => {
        return {
          productName: detail.productName,
          productDesc: detail.productDesc,
          hsnCode: detail.hsnCode,
          quantity: detail.quantity,
          qtyUnit: detail.qtyUnit,
          cgstRate: detail.cgstRate,
          sgstRate: detail.sgstRate,
          cessRate: detail.cessRate,
          igstRate: detail.igstRate,
          taxableAmount: detail.taxableAmount,
          cgst_amt: (+detail.taxableAmount * +detail.cgstRate) / 100,
          sgst_amt: (+detail.taxableAmount * +detail.sgstRate) / 100,
          igst_amt: (+detail.taxableAmount * +detail.igstRate) / 100,
          cess_amt: (+detail.taxableAmount * +detail.cessRate) / 100
        };
      });
      this.eWayBillItemDtlsData = data;
    },
    getEwayBillDatabyIdApi() {},
    formattedNumber(num) {
      return commonHelper.formatCurrency(num);
    },
    saveEwayBill() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (!this.editMode) {
          const data = this.eWayBillItemDtlsData.map(ele => {
            return {
              productName: ele.productName,
              productDesc: ele.productDesc,
              hsnCode: +ele.hsnCode,
              quantity: +ele.quantity,
              qtyUnit: ele.qtyUnit,
              cgstRate: +ele.cgstRate,
              sgstRate: +ele.sgstRate,
              igstRate: +ele.igstRate,
              cessRate: +ele.cessRate,
              taxableAmount: +ele.taxableAmount,
              cessNonadvol: 0
            };
          });
          const payload = {
            supplyType: this.selectedSupplyType.value,
            subSupplyType: this.selectedSubType.value,
            subSupplyDesc: '',
            docType: this.docType.value,
            docNo: this.docNum,
            docDate: this.docDate,
            fromGstin: this.gstinBillFrom,
            fromTrdName: this.nameBillFrom,
            fromAddr1: this.address1DesFrom,
            fromAddr2: this.address2DesFrom,
            fromPlace: this.placeDesFrom,
            fromPincode: +this.pinDesFrom,
            actFromStateCode: +this.selectedStateBillFrom.value,
            fromStateCode: +this.selectedStateBillFrom.value,
            toGstin: this.gstinBillTo,
            toTrdName: this.nameBillTo,
            toAddr1: this.address1ShipTo,
            toAddr2: this.address2ShipTo,
            toPlace: this.placeShipTo,
            toPincode: +this.pinDesTo,
            actToStateCode: +this.selectedStateBillTo.value,
            toStateCode: +this.selectedStateBillTo.value,
            transactionType: this.selectedTrxType.value,
            otherValue: '-100',
            totalValue: 0,
            cgstValue: 0,
            sgstValue: 0,
            igstValue: 0,
            cessValue: 0,
            cessNonAdvolValue: 0,
            totInvValue: 0,
            transporterId: this.transporterId ? this.transporterId : '',
            transporterName: this.transporterName ? this.transporterName : '',
            transDocNo: this.trnsportDocNum ? this.trnsportDocNum : '',
            transMode: this.selectedMode.value,
            transDistance: this.transDistance,
            transDocDate: this.trnsportDocDate ? this.trnsportDocDate : '',
            vehicleNo: this.vehicleNum,
            vehicleType: this.selectedVehicleType.value,
            itemList: data
          };
          this.loader = true;
          this.$store
            .dispatch('eWayBill/saveEwayBill', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                const result = response.data.data;
                this.gebId = result.gebId;
                this.gebButtonFlag = this.gebId ? true : false;
                this.getEwayBillById();
                this.$bvToast.toast(response.data.message, {
                  title: 'Alert',
                  variant: 'success',
                  solid: true
                });
              } else {
                this.$bvToast.toast(response.response.data.message, {
                  title: 'Alert',
                  variant: 'danger',
                  solid: true
                });
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
      }
    },
    generateEwayBill() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        // if (!this.editMode) {
        const data = this.eWayBillItemDtlsData.map(ele => {
          return {
            productName: ele.productName,
            productDesc: ele.productDesc,
            hsnCode: +ele.hsnCode,
            quantity: +ele.quantity,
            qtyUnit: ele.qtyUnit,
            cgstRate: +ele.cgstRate,
            sgstRate: +ele.sgstRate,
            igstRate: +ele.igstRate,
            cessRate: +ele.cessRate,
            taxableAmount: +ele.taxableAmount,
            cessNonadvol: 0
          };
        });
        const payloadData = {
          supplyType: this.selectedSupplyType.value,
          subSupplyType: this.selectedSubType.value,
          subSupplyDesc: '',
          docType: this.docType.value,
          docNo: this.docNum,
          docDate: this.docDate,
          fromGstin: this.gstinBillFrom,
          fromTrdName: this.nameBillFrom,
          fromAddr1: this.address1DesFrom,
          fromAddr2: this.address2DesFrom,
          fromPlace: this.placeDesFrom,
          fromPincode: +this.pinDesFrom,
          actFromStateCode: +this.selectedStateBillFrom.value,
          fromStateCode: +this.selectedStateBillFrom.value,
          toGstin: this.gstinBillTo,
          toTrdName: this.nameBillTo,
          toAddr1: this.address1ShipTo,
          toAddr2: this.address2ShipTo,
          toPlace: this.placeShipTo,
          toPincode: +this.pinDesTo,
          actToStateCode: +this.selectedStateBillTo.value,
          toStateCode: +this.selectedStateBillTo.value,
          transactionType: this.selectedTrxType.value,
          otherValue: '-100',
          totalValue: 0,
          cgstValue: 0,
          sgstValue: 0,
          igstValue: 0,
          cessValue: 0,
          cessNonAdvolValue: 0,
          totInvValue: 0,
          transporterId: this.transporterId ? this.transporterId : '',
          transporterName: this.transporterName ? this.transporterName : '',
          transDocNo: this.trnsportDocNum ? this.trnsportDocNum : '',
          transMode: this.selectedMode.value,
          transDistance: this.transDistance,
          transDocDate: this.trnsportDocDate ? this.trnsportDocDate : '',
          vehicleNo: this.vehicleNum,
          vehicleType: this.selectedVehicleType.value,
          itemList: data
        };
        const payload = {
          data: payloadData,
          gebId: this.gebId
        };
        this.loader = true;
        this.$store
          .dispatch('eWayBill/generateEwayBill', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.$bvToast.toast(response.data.message, {
                title: 'Alert',
                variant: 'success',
                solid: true
              });
            } else {
              this.$bvToast.toast(response.response.data.message, {
                title: 'Alert',
                variant: 'danger',
                solid: true
              });
            }
          })
          .catch(() => {
            this.loader = false;
          });
        // }
      }
    },
    getEwayBillById() {
      const payload = {
        gebId: this.gebId
      };
      this.$store
        .dispatch('eWayBill/getEwayBillById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            this.autoFilledData(result);
            if (result.ewbDate) {
              // this.extendCancelValidation('28/06/2024 04:14:00 PM', 'cancel');
              this.extendCancelValidation(result.ewbDate, 'cancel');
            }
            if (result.ewbValidDate) {
              this.extendCancelValidation(result.ewbValidDate, 'extend');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      //   if (this.editMode) {
      this.eWayBillItemDtlsData.push({
        productName: null,
        productDesc: null,
        hsnCode: null,
        quantity: null,
        qtyUnit: null,
        // taxable_value: null,
        cgstRate: null,
        sgstRate: null,
        cessRate: null,
        igstRate: null,
        taxableAmount: null,
        cgst_amt: null,
        sgst_amt: null,
        igst_amt: null,
        cess_amt: null,
        total_inv_value: null
      });
      //   }
    },
    removeRow(index) {
      this.eWayBillItemDtlsData.splice(index, 1);
    },
    calculateTax(item, index, type) {
      let calTax = 0;
      if (item.cgstRate && type === 'cgst') {
        calTax = (+item.taxableAmount * +item.cgstRate) / 100;
        item.sgstRate = item.cgstRate;
        item.igstRate = 0;
        item.igst_amt = 0;
        this.eWayBillItemDtlsData[index].cgst_amt = calTax.toFixed(2);
        this.eWayBillItemDtlsData[index].sgst_amt = calTax.toFixed(2);
        this.eWayBillItemDtlsData = JSON.parse(
          JSON.stringify(this.eWayBillItemDtlsData)
        );
      } else if (item.sgstRate && type === 'sgst') {
        calTax = (+item.taxableAmount * +item.sgstRate) / 100;
        item.cgstRate = item.sgstRate;
        item.igstRate = 0;
        item.igst_amt = 0;
        this.eWayBillItemDtlsData[index].cgst_amt = calTax.toFixed(2);
        this.eWayBillItemDtlsData[index].sgst_amt = calTax.toFixed(2);
        this.eWayBillItemDtlsData = JSON.parse(
          JSON.stringify(this.eWayBillItemDtlsData)
        );
      } else if (item.igstRate && type === 'igst') {
        calTax = (+item.taxableAmount * +item.igstRate) / 100;
        item.cgstRate = 0;
        item.cgst_amt = 0;
        item.sgst_amt = 0;
        item.sgstRate = 0;
        item.igst_amt = calTax.toFixed(2);
        this.eWayBillItemDtlsData = JSON.parse(
          JSON.stringify(this.eWayBillItemDtlsData)
        );
      } else if (item.cessRate && type === 'cess') {
        calTax = (+item.taxableAmount * +item.cessRate) / 100;
        item.cess_amt = calTax.toFixed(2);
        this.eWayBillItemDtlsData = JSON.parse(
          JSON.stringify(this.eWayBillItemDtlsData)
        );
      }
    },
    showHideUpdateEwayBillModal(flag, type) {
      this.updateProps = {};
      this.updateFormType = type;
      if (type === 'partB') {
        this.updateProps.formType = type;
        this.updateProps.ewbNo = this.eWayBillNum;
        this.updateProps.gebId = this.gebId;
        this.updateProps.mode = this.modeOptions;
        this.updateProps.selectedMode = {
          text: this.selectedMode.text,
          value: this.selectedMode.value
        };
        this.updateProps.vehicleType = this.vehicleTypeOptions;
        this.updateProps.vehicleNum = this.vehicleNum;
        this.updateProps.trnsDocNo = this.trnsportDocNum;
        this.updateProps.trnsDocDate = this.trnsportDocDate;
        this.updateProps.fromPlace = this.placeDesFrom;
        this.updateProps.fromState = {
          text: this.selectedStateBillFrom.text,
          value: this.selectedStateBillFrom.value
        };
        this.updateProps.fromStateOptions = this.stateListBillFrom;
        this.updateProps.selectedVehicleType = {
          text: this.selectedVehicleType.text,
          value: this.selectedVehicleType.value
        };
        this.showUpdateEwayBillModal = flag;
      } else if (type === 'transporterId') {
        this.updateProps.formType = type;
        this.updateProps.ewbNo = this.eWayBillNum;
        this.updateProps.trnsId = this.transporterId;
        this.showUpdateEwayBillModal = flag;
      } else if (type === 'canEwaybill') {
        if (this.cancelEwayBillFlag) {
          this.updateProps.formType = type;
          this.updateProps.ewbNo = this.eWayBillNum;
          this.showUpdateEwayBillModal = flag;
        } else {
          this.$bvToast.toast(
            'E-way bill can be cancelled within 24 hours of generation of e-way bill.',
            {
              title: 'Alert',
              variant: 'danger',
              solid: true
            }
          );
        }
      } else if (type === 'extEwaybill') {
        this.updateProps.formType = type;
        this.updateProps.ewbNo = this.eWayBillNum;
        this.updateProps.transDistance = this.rowData.transDistance;
        this.showUpdateEwayBillModal = flag;
      }
    },

    extendCancelValidation(givenDateTime, type) {
      const [date, time, period] = givenDateTime.split(' ');
      const [day, month, year] = date.split('/');
      const [hours, minutes, seconds] = time.split(':');

      let givenDate = new Date(
        `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
      );
      if (period === 'PM' && hours !== '12') {
        givenDate.setHours(givenDate.getHours() + 12);
      } else if (period === 'AM' && hours === '12') {
        givenDate.setHours(0);
      }
      const now = new Date();
      // const istOffset = 5.5 * 60 * 60 * 1000;
      const currentTime = new Date(now.getTime());
      if (type === 'extend') {
        const beforeTime = new Date(givenDate.getTime() - 8 * 60 * 60 * 1000);
        const afterTime = new Date(givenDate.getTime() + 8 * 60 * 60 * 1000);
        if (currentTime >= beforeTime && currentTime <= afterTime) {
          this.extendEwayBillFlag = true;
        }
      }
      if (type === 'cancel') {
        const beforeTime = new Date(givenDate.getTime() - 0 * 60 * 60 * 1000);
        const afterTime = new Date(givenDate.getTime() + 24 * 60 * 60 * 1000);
        if (currentTime >= beforeTime && currentTime <= afterTime) {
          this.cancelEwayBillFlag = true;
        }
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
